import { FC } from 'react';
import { FormControlLabel, Grid, Typography } from '@mui/material';
import { useFormikContext } from 'formik';
import { Checkbox, TextField, CardTitle, Card, Loader } from '../../components';
import { ISiteGetResponse } from '../../models';

interface IEditAdditionalInformationProps {
  handleFormChange: (val?: any) => void;
  isExpanded?: boolean;
  initialExpand?: boolean;
  isLoadingSiteDefinitions: boolean;
}

export const EditAdditionalInformation: FC<IEditAdditionalInformationProps> = ({
  handleFormChange,
  isExpanded,
  initialExpand = true,
  isLoadingSiteDefinitions,
}) => {
  const {
    values: { additionalSiteInformation },
  }: { values: ISiteGetResponse } = useFormikContext();
  const { handleBlur, setFieldValue, values } = useFormikContext<ISiteGetResponse>();

  return (
    <Card>
      <CardTitle
        title="Additional Information"
        withExpand
        initialExpand={initialExpand}
        overrideExpand={isExpanded}
      >
        {isLoadingSiteDefinitions && <Loader type="overlay" />}
        <Grid container spacing={2} mt={0.25}>
          {Array.isArray(additionalSiteInformation) &&
            additionalSiteInformation.map((additionalSiteInformation, index) => (
              <Grid
                item
                xs={12}
                sm={6}
                md={4}
                lg={3}
                key={additionalSiteInformation.userDefinedSiteDefId}
              >
                <TextField
                  name={`additionalSiteInformation.${index}.value`}
                  label={additionalSiteInformation.description}
                  onBlur={e => {
                    handleBlur(e);
                  }}
                  onChange={val => {
                    let copy = [...values.additionalSiteInformation];
                    copy[index] = {
                      ...copy[index],
                      value: val,
                    };
                    handleFormChange({
                      ...values,
                      additionalSiteInformation: copy,
                    });
                  }}
                />
              </Grid>
            ))}
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <FormControlLabel
              control={
                <Checkbox
                  name="chargeForChemicals"
                  onChange={e => {
                    const newValues = {
                      ...values,
                      chargeForChemicals: Boolean(e.target.checked),
                    };
                    setFieldValue('chargeForChemicals', Boolean(e.target.checked));
                    handleFormChange(newValues);
                  }}
                />
              }
              label={<Typography variant="body2">Normally pays for chemicals</Typography>}
            />
          </Grid>
        </Grid>
      </CardTitle>
    </Card>
  );
};
