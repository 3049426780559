import { ButtonProps } from '@mui/base';
import { Button } from '@mui/material';
import React, { FC } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSave } from '@fortawesome/free-solid-svg-icons';
import { kebabCase } from 'lodash';

interface ISaveButton extends ButtonProps {
  text?: string;
  icon?: any;
  handleSave?: (e: React.MouseEvent) => void;
  sx?: any;
  testId?: string;
}

export const SaveButton: FC<ISaveButton> = ({
  text,
  icon,
  handleSave,
  testId = 'save-button',
  ...props
}) => {
  return (
    <Button
      {...props}
      type={handleSave ? 'button' : 'submit'}
      onClick={handleSave}
      startIcon={<FontAwesomeIcon icon={icon ?? faSave} />}
      color="secondary"
      data-testid={kebabCase(text ?? 'save') + '-button'}
    >
      {text ?? 'Save'}
    </Button>
  );
};
