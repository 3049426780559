import { format, startOfWeek, endOfWeek, startOfDay } from 'date-fns';
import { IDateRange } from '../models';

const createDateRangeInputValue = (startDate: Date, endDate: Date) => {
  const fromDate = format(startDate, 'M/dd/yyyy');
  const toDate = format(endDate, 'M/dd/yyyy');

  return `${fromDate} - ${toDate}`;
};

export const createDateRangeModel = (startDate: Date, endDate: Date): IDateRange => {
  return {
    startDate,
    endDate,
    key: 'selection',
    inputValue: createDateRangeInputValue(startDate, endDate),
  };
};

export const setStartOfWeek = (date: Date) => startOfWeek(date, { weekStartsOn: 1 });
export const setEndOfWeek = (date: Date) => startOfDay(endOfWeek(date, { weekStartsOn: 1 }));

// 2023-11-15T18:00:00-05:00 -> 2023-11-15T18:00:00
// so the FE displays the correct time based on where the current user
// 2023-11-15T18:00:00Z -> 2023-11-15T18:00:00
export const removeOfficeTimeOffset = (date: string | undefined) => {
  if (date?.includes('Z')) {
    return date.replace('Z', '');
  }
  return date?.slice(0, -6);
};

export const getRawDateTime = (date: Date, time?: Date) => {
  const day = new Date(date).getDate();
  const month = new Date(date).getMonth() + 1; // need to add one since month is an index
  const year = new Date(date).getFullYear();
  let timeHours = undefined;
  let timeMinutes = undefined;
  if (time) {
    timeHours = new Date(time).getHours();
    timeMinutes = new Date(time).getMinutes().toString().padStart(2, '0');
  }

  return {
    timeHours,
    timeMinutes,
    day,
    month,
    year,
  };
};

export const formatRawDateTime = (date: Date, time: Date) => {
  const { timeHours, timeMinutes } = getRawDateTime(date, time);
  if (timeHours && timeMinutes) {
    return `${formatRawDate(date)} ${timeHours}:${timeMinutes}Z`;
  }
  return formatRawDate(date);
};

export const formatRawDate = (date: Date) => {
  const { year, month, day } = getRawDateTime(date);
  return `${year}-${month}-${day}`;
};

export const formatDayMonthYearDate = (date: Date) => {
  const { year, month, day } = getRawDateTime(date);
  return `${year}/${month}/${day}`;
};

export const validateStartDate = (startDate: Date, endDate: Date, allowEqual?: boolean) => {
  if (allowEqual) {
    return startDate <= endDate;
  }
  return startDate < endDate;
};
export const validateEndDate = (startDate: Date, endDate: Date, allowEqual?: boolean) => {
  if (allowEqual) {
    return startDate >= endDate;
  }
  return startDate > endDate;
};
